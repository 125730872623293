import React from 'react'
import styled, { css } from 'styled-components'
import { blue, yellow, white, black } from '../utlis/colors'

const Button = styled.button`
  background-color: ${props => (props.yellow ? yellow : blue)};
  box-shadow: none;
  border-radius: 6px;
  border: none;
  height: ${props => (props.bigger ? '60px' : '50px')};
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.yellow ? '#e6d60f' : '#133479')};
    transition: all ease 0.3s;
  }
  ${props =>
    props.formBtn &&
    css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 30px;
        top: 50%;
        margin-top: -4px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
      }
    `}
  ${props =>
    props.back &&
    css`
      position: relative;
      padding: 0 15px;
      &::after {
        content: '';
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -5px;
        border: solid ${black};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
      }
    `}
  @media screen and (max-width: 768px) {
    display: ${props => props.mobileCenter && 'block'};
    height: ${props => (props.formBtn ? '60px' : props.bigger && '50px')};
    margin: ${props => props.mobileCenter && '0 auto 30px auto'};
    width: ${props => (props.formBtn ? '100%' : 'auto')};
  }
`
const Paragraph = styled.p`
  color: ${props => (props.black ? black : props.blue ? blue : white)};
  font-family: 'Larsseit-Medium';
  font-size: ${props =>
    (props.bigger && '19px') || (props.fontSize && props.fontSize) || '17px'};
  padding: 0 25px;
  line-height: ${props =>
    (props.bigger && '25px') || (props.fontSize && props.fontSize) || '17px'};
  min-width: ${props => (props.bigger && '220px') || 'auto'};
  @media screen and (max-width: 768px) {
    padding: 0 15px;
    font-size: 19px;
  }
`

const CustomButton = ({
  yellow,
  black,
  blue,
  bold,
  height,
  fontSize,
  bigger,
  children,
  onClick,
  type,
  mobileCenter,
  formBtn,
  back,
  ...props
}) => {
  return (
    <Button
      mobileCenter={mobileCenter}
      yellow={yellow}
      height={height}
      formBtn={formBtn}
      back={back}
      onClick={onClick}
      bigger={bigger}
      type={type}
      {...props}
    >
      <Paragraph
        black={black}
        blue={blue}
        white={white}
        fontSize={fontSize}
        bigger={bigger}
        bold={bold}
      >
        {children}
      </Paragraph>
    </Button>
  )
}

export default CustomButton
