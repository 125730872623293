import React from 'react'
import styled from 'styled-components'
import H2 from './h2'
import Paragraph from './paragraph'
import { white } from '../utlis/colors'
import { FormattedMessage } from 'react-intl'
import checkmark from '../images/checkmark.svg'

const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  position:relative;
  z-index:2;
  margin-top:${props => props.isServices ? "-160px" : "-190px"};
  margin-bottom:${props => props.isServices && "70px"};
  @media screen and (max-width: 1080px){
    margin-top:${props => !props.isServices && "0px"};
    flex-direction: column;
    width: 100%;
  }
`
const OfferItem = styled.div`
  width: calc((100% / 3) - 20px);
  background-color: ${props => props.isServices ? "#f9f9f9" : white};
  min-height: 505px;
  padding: 40px 40px 80px 80px;
  border-radius: 10px;
  min-height: 100%;
  display:flex;
  flex-direction: column;
  position:relative;
  
  @media screen and (max-width: 1080px) {
    padding: 40px 40px 80px 40px;
 
    width: 100%;
    margin-bottom:20px;
    flex-direction: row;
    padding: 50px;
  }
  @media screen and (max-width:768px){
    width:100%;
    height:auto;
    flex-direction: column;
    min-height:305px;
    padding:30px 30px 20px 50px;
  }

`
const UL = styled.ul`
  list-style: none;
  flex: 1;
`
const LI = styled.li`
  margin-bottom: 15px;
  position: relative;
  &:after {
    content: url(${checkmark});
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: 100%;
    left: -40px;
    top: 4px;
    @media screen and (max-width: 1080px) {
      left: -25px;
    }
  }
`
const Title = styled(H2)`
  margin-left: -40px;
  font-size: 40px;
  line-height: 45px;
  @media screen and (max-width: 768px) {
    margin-left: -25px
  }

  @media screen and (max-width: 1080px){
    width: 50%;
    margin-left: 0px !important;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 1080px) {
    margin-left: -25px
  }
`

const Note = styled(Paragraph)`
  @media screen and (min-width: 1080px){
		margin: 40px -40px -40px;
	}
`;

export default function OffersList({ isServices }) {
	return (
		<Wrapper isServices={isServices}>
			<OfferItem isServices={isServices}>
				<Title color="blue"><FormattedMessage id="offerListBasic" /></Title>
				<UL>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListAdvertising" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListAdvertisingText" />
						</Paragraph>
					</LI>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListPriceManagement" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListPriceManagementText" />
						</Paragraph>
					</LI>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListOnlineSupport" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListOnlineSupportText" />
						</Paragraph>
					</LI>
				</UL>
			</OfferItem>

			<OfferItem isServices={isServices}>
				<Title color="blue"><FormattedMessage id="offerListStandard" /></Title>
				<UL>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListBasicPlus" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListBasicPlusText" />
						</Paragraph>
					</LI>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListAdministrativeWork" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListAdministrativeWorkText" />
						</Paragraph>
					</LI>
				</UL>
			</OfferItem>

			<OfferItem isServices={isServices}>
				<Title color="blue"><FormattedMessage id="offerListFull" /></Title>
				<UL>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListStandardPlus" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListStandardPlusText" />
						</Paragraph>
					</LI>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListMaintenance" />
						</Paragraph>
						<Paragraph regular>
							<FormattedMessage id="offerListMaintenanceText" />
						</Paragraph>
					</LI>
					<LI>
						<Paragraph bold uppercase>
							<FormattedMessage id="offerListCheckInCheckOut" />
						</Paragraph>
						<Paragraph regular><FormattedMessage id="offerListCheckInCheckOutText" /></Paragraph>
					</LI>
					<Note>
						<FormattedMessage id="offerListFullNote" />
					</Note>
				</UL>
			</OfferItem>
		</Wrapper>
	)
}
