import React from 'react'
import styled from 'styled-components'
import { darkBlue, white } from '../utlis/colors';

const HamburgerWrapper = styled.div`
  @media screen and (min-width:961px){
    display:none;
  }
  `

const Container = styled.div`
    display: inline-block;
    cursor: pointer;
    padding: 15px;

    &.isOpen .bar1{
      -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-2px, 2px) ;
    }
    &.isOpen .bar2{
      opacity: 0;
    }
    &.isOpen .bar3{
      -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-5px, -5px);
    }
`

const Bar = styled.div`
    width: 19px;
    height: 2px;
    background-color: white;
    margin: 3px 0;
    transition: 0.4s;
  `

const Hamburger = ({ toggleHamburger, isOpen, isHomePage }) => {
  if (typeof document !== "undefined") {
    const bodyTag = document.getElementsByTagName("body")[0]
    if (isOpen) {
      bodyTag.classList.add("hidden")
    }
    else {
      bodyTag.classList.remove("hidden")
    }
  }

  return (
    <HamburgerWrapper>
      <Container className={`container ${isOpen ? "isOpen" : ""}`} onClick={() => toggleHamburger()}>
        <Bar isHomePage={isHomePage} className="bar1"></Bar>
        <Bar isHomePage={isHomePage} className="bar2"></Bar>
        <Bar isHomePage={isHomePage} className="bar3"></Bar>
      </Container>
    </HamburgerWrapper>

  )
}

export default Hamburger;