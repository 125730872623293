import styled, { css } from 'styled-components'
import { blue } from '../utlis/colors';
import pickColor from '../utlis/pickColor'


const Paragraph = styled.p`
  font-family:${props => props.bold ? 'Larsseit-Bold' : props.larrseit ? "Larsseit-Medium" : "Grotesk-Medium"};
  color:${props => pickColor(props.color)};
  margin-bottom:${props => props.offset};
  line-height:29px;
  font-size:21px;
  text-transform:${props => props.uppercase && "uppercase"};
  font-family:${props => props.regular && 'Larsseit-Regular'};
  @media screen and (max-width:768px){
    font-size:17px;
    line-height:25px;
  }
   ${props => props.italic && css`
    color:${blue};
    font-size:32px;
    line-height:42px;
    font-style:italic;
    font-family:"Larsseit-Bold";
    margin-bottom:35px;`

  }`

export default Paragraph;