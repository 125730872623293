import styled from 'styled-components'
import pickColor from '../utlis/pickColor'

const H1 = styled.h1`
  font-family:"Larsseit-Bold";
  font-size:50px;
  line-height:60px; 
  color:${props => pickColor(props.color)};
  @media screen and (max-width:768px){
    font-size: 37px;
    line-height: 45px;
    }
`
export default H1;