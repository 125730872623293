import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import CustomButton from './button'
import LocalizedLink from '../components/localisedLink'
import { FormattedMessage, injectIntl } from 'react-intl'
import LanguageBar from './languageBar'
import Hamburger from './hamburger'
import logoBlueEN from '../images/logo/LogoBlueEN.svg'
import logoBlueHR from '../images/logo/LogoBlueHR.svg'
import logoYellowEN from '../images/logo/LogoYellowEN.svg'
import logoYellowHR from '../images/logo/LogoYellowHR.svg'

const MainWrapper = styled.div`
  margin: 0 -15px;
  @media screen and (max-width: 1180px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 960px) {
    ${props =>
      props.isOpen &&
      css`
        background: white;
        height: calc(100vh - 85px);
        left: 0;
        right: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 22;
        padding: 0 15px;
      `}
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    ${props =>
      props.isOpen &&
      css`
        height: calc(100vh - 65px);
      `}
  }
  @media screen and (max-width: 413px) {
    ${props =>
      props.isOpen &&
      css`
        min-height: 100vh;
        overflow: scroll;
        height: 100vh;
      `}
  }

  @media screen and (orientation: landscape) {
    ${props =>
      props.isOpen &&
      css`
        height: 100vh;
        min-height: 100vh;
        overflow: scroll;
      `}
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-items: center;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 960px) {
    position: relative;
    ${props =>
      props.isOpen &&
      css`
        position: initial;
      `}
  }
`
const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: none;
    ${props =>
      props.isOpen &&
      css`
        position: fixed;
        background: white;
        overflow: scroll;
        display: block;
        padding-top: 40px;
        text-align: center;
        width: calc(100% - 30px);
      `}
    @media screen and (max-width: 768px) {
      ${props =>
        props.isOpen &&
        css`
          top: auto;
          bottom: 0;
        `}
    }

    @media screen and (max-width: 320px) {
      ${props =>
        props.isOpen &&
        css`
          top: 90px;
          bottom: 0;
        `}
    }
    @media screen and (orientation: landscape) {
      ${props =>
        props.isOpen &&
        css`
          top: 90px;
        `}
    }
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    display: flex;
    text-align: center;
    justify-content: flex-end;
    width: 100%;
  }
`

const ListElement = styled.li`
  display: inline;
  margin-right: 40px;
  @media screen and (max-width: 960px) {
    margin-right: 0px;
    margin-bottom: 15px;
  }
`

const HamburgerWrapper = styled.div`
  @media screen and (min-width: 961px) {
    display: none;
  }
  @media screen and (min-width: 961px) {
    & > a {
      display: none;
    }
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
  @media screen and (max-width: 560px) {
    & > a {
      display: none;
    }
  }
`
const Image = styled.img`
  height: 90px;
  font-size: 0;
  margin: 0 15px;
`
const MobileInfo = styled.div`
  @media screen and (max-width: 413px) {
    display: block;
    font-size: 0;
    margin-top: 20px;
  }
  @media screen and (min-width: 414px) {
    display: none;
  }
`

const Header = ({
  isHomePage,
  isOpen,
  toggleHamburger,
  isBlogPost,
  locale,
}) => {
  const logoYellow = locale === 'hr' ? logoYellowHR : logoYellowEN
  const logoBlue = locale === 'hr' ? logoBlueHR : logoBlueEN
  return (
    <MainWrapper isOpen={isOpen}>
      <HeaderWrapper isOpen={isOpen}>
        <Link className="logo" to={locale === 'hr' ? '/' : 'en'}>
          <img alt="logo" src={isOpen ? logoBlue : logoYellow} />
        </Link>
        <NavigationWrapper isOpen={isOpen}>
          <List isOpen={isOpen}>
            <ListElement>
              <LocalizedLink
                isopen={isOpen.toString()}
                onClick={() => toggleHamburger()}
                ishomepage={isHomePage}
                activeClassName="active-link"
                to="/vodjenje-apartmana-split"
                title={
                  locale === 'hr'
                    ? 'Vođenje, održavanje i čišćenje apartmana Split | Litto Booking'
                    : 'Property management, advertising and maintenance | Litto Booking'
                }
              >
                <FormattedMessage id="servicesPage" />
              </LocalizedLink>
            </ListElement>
            <ListElement>
              <LocalizedLink
                isopen={isOpen.toString()}
                onClick={() => toggleHamburger()}
                ishomepage={isHomePage}
                activeClassName="active-link"
                to="/o-nama"
                title={
                  locale === 'hr'
                    ? 'O nama | Litto Booking'
                    : 'About us | Litto Booking'
                }
              >
                <FormattedMessage id="aboutPage" />
              </LocalizedLink>
            </ListElement>

            <ListElement>
              <LocalizedLink
                isopen={isOpen.toString()}
                onClick={() => toggleHamburger()}
                ishomepage={isHomePage}
                activeClassName="active-link"
                to="/cesta-pitanja"
                title={
                  locale === 'hr'
                    ? 'Često postavljena pitanja | Litto Booking'
                    : 'FAQ | Litto Booking'
                }
              >
                <FormattedMessage id="faqPage" />
              </LocalizedLink>
            </ListElement>
            {locale === 'hr' && (
              
                <ListElement>
                  <LocalizedLink
                    isopen={isOpen.toString()}
                    onClick={() => toggleHamburger()}
                    ishomepage={isHomePage}
                    className={isBlogPost && 'active-link'}
                    activeClassName="active-link"
                    to="/novosti-iz-turizma-i-vodici-za-iznajmljivace"
                    title="Novosti iz turizma i vodiči za iznajmljivače | Litto Booking"
                  >
                    <FormattedMessage id="blogPage" />
                  </LocalizedLink>
                </ListElement>

                // <ListElement>
                //   <LocalizedLink
                //     isopen={isOpen.toString()}
                //     onClick={() => toggleHamburger()}
                //     ishomepage={isHomePage}
                //     className={isBlogPost && 'active-link'}
                //     activeClassName="active-link"
                //     to="/preuzmi-besplatni-vodic-za-iznajmljivace"
                //     title="Preuzmi besplatni vodic za iznajmljivače | Litto Booking"
                //   >
                //     <FormattedMessage id="ebookPage" />
                //   </LocalizedLink>
                // </ListElement>
              
            )}
          </List>
          <LocalizedLink
            isopen={isOpen.toString()}
            ishomepage={isHomePage}
            activeClassName="active-link"
            to="/dogovori-sastanak"
            title={
              locale === 'hr'
                ? 'Dogovori sastanak | Litto Booking'
                : 'Arrange meeting | Litto Booking'
            }
          >
            <CustomButton yellow blue onClick={() => toggleHamburger()}>
              <FormattedMessage id="meetingPage" />
            </CustomButton>
          </LocalizedLink>
          <LanguageBar isinnavigation locale={locale} />
          {/* <MobileInfo>
            <Paragraph bold color="blue">
              <a
                href="tel:+385955217295"
                style={{
                  color: 'inherit',
                  fontFamily: 'inherit',
                  textDecoration: 'none',
                }}
              >
                +385 95 5217 295‬
              </a>
            </Paragraph>
            <Paragraph bold color="blue">
              <a
                href="mailto:info@litto.agency"
                style={{
                  color: 'inherit',
                  fontFamily: 'inherit',
                  textDecoration: 'none',
                }}
              >
                info@litto.agency
              </a>
            </Paragraph>
            <Paragraph>Have a question?</Paragraph>
            <Paragraph offset={'15px'}>We are avaliable 24/7!</Paragraph>
            <Image alt="header info icon 2" src={headerinfoicon} />
          </MobileInfo> */}
        </NavigationWrapper>
        <HamburgerWrapper>
          <LocalizedLink
            isopen={isOpen.toString()}
            ishomepage={isHomePage}
            activeClassName="active-link"
            to="/dogovori-sastanak"
            title={
              locale === 'hr'
                ? 'Dogovori sastanak | Litto Booking'
                : 'Arrange meeting | Litto Booking'
            }
          >
            <CustomButton onClick={() => toggleHamburger()}>
              <FormattedMessage id="meetingPage" />
            </CustomButton>
          </LocalizedLink>
          <Hamburger
            isHomePage={isHomePage}
            isOpen={isOpen}
            toggleHamburger={toggleHamburger}
          />
        </HamburgerWrapper>
      </HeaderWrapper>
    </MainWrapper>
  )
}

export default injectIntl(Header)
