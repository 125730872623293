import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './baseStyles'
import Paragraph from './paragraph'
import pickColor from '../utlis/pickColor'
import { blue } from '../utlis/colors'
import Button from './button'

const BoxWrapper = styled(Wrapper)`
  background-color:${blue};
  overflow: hidden;
  padding: ${props => (props.isBlogPost ? '90px 0 150px 0' : '180px 0 10px 0')};
  width: calc(100% + 30px) !important;
  margin: 0 -15px 45px!important;
  @media screen and (min-width: 1180px) and (max-width: 1200px) {
    overflow: unset;
  }
  @media screen and (max-width: 768px) {
    padding: ${props =>
      props.isBlogPost ? '90px 0 150px 0' : '120px 0 10px 0'};
  }
`
const Flex = styled.div`
  width: calc((100% / 12) * 10);
  margin-left: calc((100% / 12) * 2);
  width: calc((100% / 12) * 10);
  min-height: ${props => !props.isBlogPost && '350px'};
  max-height: ${props => !props.isBlogPost && '350px'};
  position: relative;
  display: flex;

  @media screen and (max-width: 768px) {
    min-height: ${props => !props.isBlogPost && 'auto'};
    max-height: ${props => !props.isBlogPost && 'inherit'};
    margin-left: auto;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    display: block;
  }
`

const ContentWrapper = styled.div`
  min-width: 50%;
  max-width: 75%;
  padding-right: 25px;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    width: 290px;
    margin: 0 auto;
    padding-right: 0;
  }
  button{
    margin-top: 20px;
  }
`
const Image = styled.img`
    width: 90%;
    margin-left: auto;
    display: block;
`
const ImageWrapper = styled.div`
  overflow: hidden;
  margin-bottom: -200px;
  @media screen and (max-width: 1200px) {
    left: calc((100% / 12) * 6);
  }
  @media screen and (min-width: 1180px) and (max-width: 1200px) {
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 200px;
      background: white;
      bottom: -9px;
      left: 0;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    height: 388px;
    width: 388px;
    left: auto;
    bottom: -100px;
    margin: -70px auto 0 auto;
  }
  @media screen and (max-width: 414px) {
    left: -10px;
  }

  @media screen and (max-width: 320px) {
    width: 320px;
    left: -20px;
  }
`
const ExtendedH2 = styled.h1`
  font-family: 'Larsseit-ExtraBold';
  font-size: 50px;
  line-height: 56px;
  padding-bottom: 15px;
  color: ${props => pickColor(props.color)};
  @media screen and (max-width: 992px) {
    font-size: 40px;
    line-height: 45px;
  }
`

const Hero = ({
  title,
  text,
  heroImage,
  order,
  isBlogPage,
  isOpen,
  isBlogPost,
  isServices,
  isRecommend,
  cta
}) => {

  return (
    <>
      <BoxWrapper
        isRecommend={isRecommend}
        isBlogPage={isBlogPage}
        isBlogPost={isBlogPost}
        isServices={isServices}
        style={{ height: isOpen && '100%' }}
      >
        <Flex isBlogPost={isBlogPost}>
          <ContentWrapper isServices={isServices}>
            <ExtendedH2 isServices={isServices} color="yellow">
              {title}
            </ExtendedH2>
            <Paragraph color="white">{text}</Paragraph>
            {cta && <Button yellow black onClick={cta.onClick}>{cta.content}</Button>}
          </ContentWrapper>
          {!isBlogPost && heroImage && (
            <ImageWrapper>
              <Image order={order} src={heroImage} alt="hero image" />
            </ImageWrapper>
          )}
        </Flex>
      </BoxWrapper>
    </>
  )
}
export default Hero
