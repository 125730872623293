import React from 'react'
import locales from '../constants/locales'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { darkBlue } from '../utlis/colors'
import { injectIntl } from 'react-intl';
import HR from '../images/HR.svg'
import EU from '../images/EU.svg'

const LanguageLink = styled(Link)`
  display:${props => !props.isinnavigation ? "flex" : "none"};;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  position:${props => props.isinnavigation ? "none" : "absolute"};
  right: ${props => !props.isinnavigation && "0"};
  width:${props => props.isinnavigation && "100%"};
  margin-top:${props => props.isinnavigation && "15px"};
  padding-bottom:${props => props.isinnavigation && "115px"};
  
  @media screen and (max-width:960px){
    right: ${props => !props.isinnavigation && "30px"};
    display: ${props => props.isinnavigation && "flex"};
  }
  @media screen and (max-width:768px){
    display: ${props => !props.isinnavigation && "none"};
  }
  @media screen and (orientation:landscape) {
    padding-bottom:${props => props.isinnavigation && "40px"};
  }
`
const Image = styled.img`
  margin-right:8px;
`
const Paragraph = styled.p`
  color: ${props => !props.isinnavigation ? "#9b9b9b" : darkBlue};
  font-family:"Larsseit-Medium";
  font-size:15px;
  line-height:15px;
  transition:all ease 0.3s;
  text-decoration:none;
  &:hover{
    text-decoration:underline;
    transition:all ease 0.3s;
    color:${darkBlue}
  }
`

const LanguageBar = ({ locale, isinnavigation }) => {
  return (
    <>
      {locale === "hr" && <LanguageLink isinnavigation={isinnavigation} to={locales["en"].default ? '/' : `/${locales["en"].path}`}>
        <Image src={EU} alt="EU-flag" />
        <Paragraph isinnavigation={isinnavigation}>English version</Paragraph>
      </LanguageLink>}
      {locale === "en" && <LanguageLink isinnavigation={isinnavigation} to={locales["hr"].default ? '/' : `/${locales["hr"].path}`}>
        <Image src={HR} alt="HR-flag" />
        <Paragraph style={{ position: 'relative', top: '2px' }} isinnavigation={isinnavigation}>Hrvatski jezik</Paragraph>
      </LanguageLink>

      }
    </>

  )
}
export default injectIntl(LanguageBar)