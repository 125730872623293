import React from 'react'
import styled, { css } from 'styled-components'
import mask from '../images/mask.svg'
import mobileMask from '../images/mobileMask.svg'
import tabletMask from '../images/tabletMask.svg'
import Img from 'gatsby-image'
import CookieMessage from '../components/cookieMessage'

import facebook from '../images/social/visit-facebook.svg'
import instagram from '../images/social/visit-instagram.svg'
import linkedin from '../images/social/visit-linkedin.svg'

import './layout.scss'

import Header from './header'
import Footer from './footer'
import { Wrapper } from './baseStyles'
import Hero from './hero'
import Newsletter from './newsletter'
import ArrangeMeeting from './arrangeMeeting'
import FormModal from '../components/formModal'

import HeaderAbove from './headerinfo'
import Testimonial from './testimonials'
import H1 from './h1'
// import hrData from 'react-intl/intl-pluralrules/locale-data/hr' // Add locale data for de


import { IntlProvider, FormattedMessage } from 'react-intl'


import en from '../i18n/en.json'
import hr from '../i18n/hr.json'
import Offers from './offers'
import { black, blue } from '../utlis/colors'

const messages = { hr, en }

// addLocaleData([...hrData, ...enData])

const HomeImage = styled.div`
  min-height: 200px;
  margin-top: -90px;
  height: ${props => props.isHomePage && '520px'};
  position: relative;
  margin-bottom: ${props => (props.isBlogPage ? '0px' : '20px')};
  background: ${props => (props.isHomePage ? undefined : blue)};
  @media screen and (min-width: 1380px) {
    width: ${props => (props.isHomePage ? '1380px' : '100%')};
    margin: -90px auto 0 auto;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    height: ${props =>
		props.isOpen ? '100vh' : props.isHomePage ? '435px' : '100%'};
    min-height: ${props => (props.isBlogPage ? '130px' : '200px')};
    overflow: scroll;
    ${props =>
		props.isOpen &&
		css`
        margin-top: 0;
      `}
  }
  @media screen and (max-width: 1240px) {
    width: 100%;
    overflow: hidden;
  }
  &:before {
    content: '';
    position: absolute;
    background-image: url(${mask});
    bottom: -3px;
    width: 100%;
    z-index: 2;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 4%;

    background-size: cover;
    @media screen and (max-width: 960px) {
      background-image: url(${tabletMask});
      padding-top: 8%;
    }
    @media screen and (max-width: 768px) {
      background-image: url(${mobileMask});
      padding-top: 15%;
    }
  }
  ${props =>
		props.isHomePage &&
		css`
      &:after {
        content: '';
        position: absolute;
        background: ${black};
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0.25;
        z-index: 1;
      }
    `}
`

const HeroTitle = styled(H1)`
  position: absolute;
  left: 50%;
  width: calc((100% / 12) * 5);
  z-index: 2;
  text-align: center;
  top: 50%;
  margin-top: -30px;
  transform: translate(-50%, -50%);
  white-space: pre-line;
  @media screen and (max-width: 1300px) {
    width: calc((100% / 12) * 8);
    margin-top: -30px;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin-top: -15px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    white-space: pre;
  }
  @media screen and (max-width: 400px) {
    font-size: 32px;
    line-height: 36px;
    width: 95%;
    white-space: pre;
  }
`

const SocialMedia = styled.nav`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  > * {
    position: relative;
    font-family: 'Larsseit-Bold';
    margin: 30px 40px 50px;
    text-decoration: none;
    font-size: 24px;
    color: ${blue};
    padding-left: 52px;
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: 768px) {
      padding-left: 42px;
      font-size: 20px;
    }

    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 4px;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }

    &:nth-of-type(1)::before {
      background-image: url(${facebook});
    }
    &:nth-of-type(2)::before {
      background-image: url(${instagram});
    }
    &:nth-of-type(3)::before {
      background-image: url(${linkedin});
    }
  }
`

const ONE_DAY = 1000 * 60 * 60 * 24
function days_between(date1, date2) {
	const differenceMs = Math.abs(date1 - date2)

	return Math.round(differenceMs / ONE_DAY)
}

class Layout extends React.Component {
	state = {
		isOpen: false,
		isMounted: false,
		modal: false,
		bookCheck: null,
	}

	// componentDidMount = async () => {
	//   const { bookCheck } = this.state

	//   await this.setState({ bookCheck: localStorage.getItem('eBook') })

	//   const eBook = await JSON.parse(localStorage.getItem('eBook'))

	//   if (!eBook) {
	//     setTimeout(() => this.setState({ modal: true }), 2000)
	//   } else {
	//     if (
	//       !eBook.downloaded &&
	//       days_between(new Date(), new Date(eBook.time)) >= 2
	//     ) {
	//       setTimeout(() => this.setState({ modal: true }), 2000)
	//     }
	//   }
	// }

	toggleHamburger = () => {
		window.innerWidth < 960 && this.setState({ isOpen: !this.state.isOpen })
	}

	closeModal = () => {
		this.setState({ modal: false })
	}

	// toggleModal = () => {
	//   this.setState({ modal: false })
	//   localStorage.setItem(
	//     'eBook',
	//     JSON.stringify({
	//       downloaded: false,
	//       time: new Date(),
	//     })
	//   )
	// }

	render() {
		const {
			children,
			isHomePage,
			isContactUs,
			isServices,
			heroImage,
			isMeeting,
			title,
			text,
			order,
			locale,
			isBlogPage,
			isBlogPost,
			fluid,
			cta,
			hideMeeting,
			isEBookPage,
			is404Page

		} = this.props

		const { modal } = this.state
		return (
			<IntlProvider locale={locale} messages={messages[locale]}>
				<>  <Wrapper>
					<HeaderAbove />
				</Wrapper>
					<div style={{ position: 'relative' }}>


						<Wrapper>
							<Header
								toggleHamburger={this.toggleHamburger}
								isOpen={this.state.isOpen}
								isHomePage={isHomePage}
								isBlogPost={isBlogPost}
								locale={locale}
							/>
						</Wrapper>
						<HomeImage
							isOpen={this.state.isOpen}
							isHomePage={isHomePage}
							isBlogPage={isBlogPage}
						>
							{isHomePage && (
								<Img
									alt="homepage"
									fluid={fluid}
									style={{
										position: 'absolute',
										left: 0,
										top: 0,
										width: '100%',
										height: '100%',
									}}
								/>
							)}

							<Wrapper>
								{!isHomePage && (
									<Hero
										isServices={isServices}
										isBlogPage={isBlogPage}
										heroImage={heroImage}
										title={title}
										text={text}
										isContactUs={isContactUs}
										order={order}
										isBlogPost={isBlogPost}
										cta={cta}
									/>
								)}
							</Wrapper>
							{isHomePage && (
								<HeroTitle color="white">
									<FormattedMessage id="homepageHeroTitle" />
								</HeroTitle>
							)}
						</HomeImage>
						{children}
						{isHomePage && <Offers />}
						{!isMeeting && !hideMeeting && !is404Page && (
							<ArrangeMeeting locale={locale} />
						)}
						{!is404Page && (
							<div style={{ paddingBottom: isMeeting && '50px' }}>
								<Testimonial locale={locale} />
							</div>
						)}
						{!isMeeting && <Newsletter locale={locale} />}
						<SocialMedia>
							<a href="https://www.facebook.com/litto.booking" target="_blank">
								Facebook
							</a>
							<a href="https://www.instagram.com/litto.co" target="_blank">
								Instagram
							</a>
							<a
								href="https://linkedin.com/company/litto-agency"
								target="_blank"
							>
								Linkedin
							</a>
						</SocialMedia>
						<CookieMessage locale={locale} />
						<Footer locale={locale} />
					</div>
				</>
			</IntlProvider>
		)
	}
}

export default Layout
