import React from 'react'
import styled from 'styled-components'
import headerinfoicon from '../images/homepage/headerinfoicon.svg'
import { blue } from '../utlis/colors'

import { injectIntl, FormattedMessage } from 'react-intl'
import LanguageBar from './languageBar';

const HeaderInfo = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  color: ${blue};
  letter-spacing: 0.8px;
  position: relative;
  @media screen and (max-width:960px){
    justify-content: flex-start;
    padding-left:30px;
    overflow:hidden;
  }
  @media screen and (max-width:768px){
    height: 68px;
  }
  @media screen and (max-width:620px){
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    height: 68px; 
  }
`

const Image = styled.img`
  height: 90px;
  font-size: 0;
  margin: 0 15px;
  @media screen and (max-width:768px){
    position:absolute;
    right: -40px;
    top:0;
  }
  @media screen and (max-width:413px){
    width: 25%;

  }
  @media screen and (max-width:350px){
    display:none;
  }
`
const Paragraph = styled.p`
  color:${blue};
  font-size:15px;
  font-family:"Larsseit-Medium";
`

const ExtendedLink = styled.a`
   text-decoration: none;
   font-size:inherit;
   color: inherit;
   font-family:inherit;
   &:hover{
     color: #d3d3d3;
     transition: all 0.2s ease;
   }
`
const HeaderInfoBgColor = styled.div`
 background-color:white;
  @media screen and (max-width:960px){
    margin:0 -15px;
    background-color:#f9f9f9;
  }
`

const ParagraphOnTablet = styled(Paragraph)`
  @media screen and (min-width:960px){
    display:none;
  }
`
const ParagraphOnDesktop = styled(Paragraph)`
  display:none;
  @media screen and (min-width:960px){
    display:block;
}
`



const HeaderAbove = ({ intl: { locale } }) => {
  return (
    <HeaderInfoBgColor>
      <HeaderInfo>
        <div>
          <Paragraph>
            <FormattedMessage id="headerInfoText" /></Paragraph>
          <ParagraphOnTablet>
            <ExtendedLink href="tel:385911770310">+385 91 177 0310</ExtendedLink>
            – <ExtendedLink href="mailto:info@litto.agency"> info@litto.agency</ExtendedLink>
          </ParagraphOnTablet>
        </div>

        <Image alt="header info icon" src={headerinfoicon} />
        <ParagraphOnDesktop>
          <Paragraph>
            <ExtendedLink href="tel:385911770310">+385 91 177 0310</ExtendedLink>
            – <ExtendedLink href="mailto:info@litto.agency"> info@litto.agency</ExtendedLink>
          </Paragraph>
        </ParagraphOnDesktop>
        <LanguageBar locale={locale}></LanguageBar>
      </HeaderInfo>
    </HeaderInfoBgColor>
  )
}

export default injectIntl(HeaderAbove)
