import styled from 'styled-components'
import pickColor from '../utlis/pickColor'

const H2 = styled.h2`
  font-family: 'Larsseit-Bold';
  font-size: 46px;
  line-height: 49px;
  padding-bottom: 15px;
  color: ${props => pickColor(props.color)};
  @media screen and (max-width: 768px) {
    font-size: 29px;
    line-height: 37px;
  }
`
export default H2
