import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components'
import { white, blue, black, darkBlue, lightGray, gray } from '../utlis/colors'
import modalBg from '../images/ebook_desktop_modal.jpg'
import modalBgMobile from '../images/ebook_mobile_modal.jpg'
import close from '../images/close.svg'
import TextInputGroup from './textinputgroup'
import CustomButton from './button'
import { navigate } from 'gatsby'

function makeRgba(hex, alpha) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
    result[3],
    16
  )},${alpha})`
}

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${makeRgba(blue, '0.4')};
  z-index: 100;
  /* display: none; */
`
const Modal = styled.div`
  background: ${white};
  border-radius: 10px;
  width: 100%;
  display:flex;
  max-width: 980px;
  box-shadow: 0 10px 30px -10px ${black};
  /* background: url(${modalBg}); */
  background-color: ${white};
  position: relative;
  overflow: auto;
  max-height: 100%;
  background-attachment: local;
  .image{
    flex: 1;
    background: url(${modalBg});
    background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 430px;
  }
  .content{
    flex: 2;
    padding: 50px 120px 50px 0px ;
    margin-left: 2rem;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image {
      max-width: 150px;
      height: auto;
      transform: scale(0.8);
      position: absolute;
      right: -120px;
      top: -40px;
      z-index: 1;
    }
  }

  @media (max-width: 640px) {
    position:absolute;
    bottom:0;
    height: 70vh;
    width:100vw;
    overflow: hidden;
    .content{
      display: flex;
      flex-direction: column;
      /* align-items:center; */
      justify-content: space-evenly;
      padding: 20px 0;
      margin: 0;
    }
    .image{
      max-width: 150px;
      height: auto;
      transform: scale(0.5);
      position: absolute;
      right: -120px;
      top: -40px;
      z-index: 1;
    }
    
    
    
  }

  .button__text{
    color: white
  }
  .close {
    border: none;
    background: url(${close});
    position: absolute;
    right: 35px;
    top: 35px;
    width: 28px;
    height: 28px;
  }

  .small {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    @media (max-width: 640px) {
      max-width: 60%;
      z-index: 100;
      position: static;
      margin-left: 30px;
      margin-bottom: 0
    }
    
  }
  .title {
    font-size: 30px;
    line-height: 42px;
    color: ${blue};
    margin-bottom: 25px;
    /* min-width: 100%; */
    font-family: "Larsseit-ExtraBold";
    @media (max-width: 640px) {
      max-width: 50%;
      z-index: 100;
      position: static;
      margin-left: 30px;
    }

  }
  .text {
    font-size: 18px;
    color: ${darkBlue};
    line-height: 24px;
    /* position: absolute; */
    /* top: 80%; */
    /* left: 40px; */
    /* max-width: 80%; */
    /* padding-bottom: 40px; */

    @media (max-width: 992px) {
      bottom: auto;
      top: 10%;
      right: 90px;
      left: auto;
      max-width: 50%;
    }

    @media (max-width: 640px) {
      position: static;
      max-width: 70%;
      z-index: 100;
      margin-left: 30px;

    }
  }
  .consent {
    font-size: 13px;
    color: ${black};
    line-height: 18px;
    padding-left: 40px;
    position: relative;
  }
  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    min-width: 300px;
    button{
      cursor: pointer;
    }
    @media (max-width: 992px) {
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      /* height: 100px; */
     
      margin-right: 2rem;
      * {
        width: 100%;
        z-index: 300;
      }
    }
  }

  .submitBtnWrap {
    
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
  }

  .cancel {
    font-size: 20px;
    color: ${gray};
    background: none;
    border: none;
    margin-left: 2rem;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1;
  width: 1;
  color: red;
`
const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${props => (props.on === true ? 'gray' : 'white')};
  border-radius: 10px;
  border: 1px solid ${gray};
  :after {
    content: '';
    left: 8px;
    top: 4px;
    width: 5px;
    position: absolute;
    height: 10px;
    border: solid ${white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const FormModal = props => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    checked: false,
    error: {},
  })
  console.log('formState', formState);
  const { closeModal, toggleModal } = props
  const [submitted, setSubmitted] = useState(false)

  const formSubmit = async e => {
    e.preventDefault()
    try {
      const response = await fetch('.netlify/functions/sendPdf/sendPdf', {
        method: 'POST',
        body: JSON.stringify(formState),
      })
      localStorage.setItem(
        'eBook',
        JSON.stringify({
          downloaded: true,
          time: new Date(),
        })
      )
      if (response && response.ok) {
        setSubmitted(true)
        navigate('/hvala-na-prijavi')
      }
      closeModal()
    } catch (error) {
      console.error(error)
    }
  }

  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <Cover>
      <Modal>
        {window.innerWidth > 992 && (
          <>
            <div className="image"></div>
            <button class="close" onClick={toggleModal} />
          </>
        )}
        <div class="content">
          <small className="small">Besplatni priručnik</small>
          {window.innerWidth > 992 ? (
            <>
              <h4 class="title">
                Od početnika do profesionalca - vodič za uspješno iznajmljivanje
                smještaja
              </h4>
              <p class="text">
                Smatramo kako je potrebno širiti znanje, a znamo da je vrijeme
                novac, stoga smo osmislili ovu kratku knjižicu.
              </p>
            </>
          ) : (
            <>
              <h4 class="title">Preuzmi besplatni eBook</h4>
              <img
                className="image"
                src={modalBgMobile}
                alt="ebook"
                title="ebook"
              />
              <p class="text">
                Smatramo kako je potrebno širiti znanje, a znamo da je vrijeme
                novac, stoga smo osmislili ovu kratku knjižicu.
              </p>
            </>
          )}
          {/* <TextInputGroup
              name="name"
              type="text"
              label="Ime"
              onChange={onChange}
            />
            <TextInputGroup
              name="email"
              type="Email"
              label="Email"
              onChange={onChange}
            />
            <div className="consent">
              <Input
                type="checkbox"
                id="agree"
                name="agree"
                label="agree"
                checked={formState.checked}
                required
              />
              <Checkmark
                onClick={() =>
                  setFormState({ ...formState, checked: !formState.checked })
                }
                on={formState.checked}
              />
              <label htmlFor="agree">
                Pristajem dati tvrtki Litto d.o.o. sve potrebne informacije za
                svrhe ispunjavanja ovog upitnika, u skladu s pravilima
                privatnosti, uvjetima i odredbama koje sam pročitao i s kojima
                se slažem. Ovo treba izmjenit jer je kopija sa bookera
              </label> */}
          {/* </div> */}

          <div class="buttons">
            <div class="submitBtnWrap">
              <CustomButton
                blue
                white
                bold
                type="submit"
                onClick={() =>
                  navigate('/preuzmi-besplatni-vodic-za-iznajmljivace')
                }
              >
                <span class="button__text">Zanima me</span>
              </CustomButton>
            </div>
            <button class="cancel" onClick={toggleModal}>
              Ne želim!
            </button>
          </div>
        </div>
      </Modal>
    </Cover>
  )
}

export default FormModal
