module.exports={
    hr: {
        path: 'hr',
        locale: 'HR',
        default: true
    },
    en: {
        path: 'en',
        locale: 'EN',
    }
}