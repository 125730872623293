import styled from 'styled-components'

const Wrapper = styled.div`
  width: 1180px;
  padding: 0 15px;
  margin: 0 auto;
  
  @media screen and (max-width:1350px){
    width: 1080px;
  }
  @media screen and (max-width:1180px){
    width: 100%;
  }
 
`

const Section = styled.div`
  width: calc((100% / 12) * 8);
  margin-left: calc((100% / 12) * 2);
  @media screen and (max-width:1280px){
    width: calc((100% / 12) * 8);
    margin:0 auto;
  }
  @media screen and (max-width:768px){
    width:100%;
    margin:0 auto;
  }
  @media screen and (max-width:560px){
    width:100%;
    margin:0 auto;
    padding:0;
  }
`
const BaseImage = styled.img`
  width: calc((100% / 12) * 10);
  margin:0 auto;
  display:block;
  margin-bottom:35px;
  @media screen and (max-width:560px){
    width:100%;
    height:211px;
  }
`

export {
  Wrapper,
  Section,
  BaseImage
}