import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './baseStyles'
import Paragraph from './paragraph'
import CustomButton from './button'
import mask from '../images/mask.svg'
import mobileMask from '../images/mobileMask.svg'
import tabletMask from '../images/tabletMask.svg'
import couch from '../images/homepage/couch.svg'
import H2 from './h2'
import { blue } from '../utlis/colors'
import { FormattedMessage } from 'react-intl'
import LocalisedLink from '../components/localisedLink'

const ArrangeWrapper = styled.div`
  background-color: ${blue};
  padding: 90px 0 180px 0;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 40px 0 0 0;
    overflow: unset;
  }
  &:before {
    content: '';
    position: absolute;
    background-image: url(${mask});
    bottom: -3px;
    width: 100%;
    z-index: 2;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 4%;

    background-size: cover;
    @media screen and (max-width: 960px) {
      background-image: url(${tabletMask});
      padding-top: 8%;
    }
    @media screen and (max-width: 768px) {
      background-image: url(${mobileMask});
      padding-top: 15%;
    }
  }
`
const Title = styled(H2)`
  padding-bottom: 15px;
  @media screen and (max-width: 768px) {
    padding-bottom: 25px;
    margin: 0 auto 0 auto;
    text-align: center;
  }
`

const ExtendedParagraph = styled(Paragraph)`
  padding-bottom: 30px;
  width: calc((100% / 12) * 6);
  @media screen and (max-width: 768px) {
    margin: 0 auto 0 auto;
    text-align: center;
    width: 80%;
  }
`

const Image = styled.img`
  position: absolute;
  left: calc((100% / 12) * 6);
  bottom: -120px;
  @media screen and (max-width: 768px) {
    position: static;
    width: 290px;
    display: block;
    margin: 0 auto;
    position: relative;
    bottom: -10px;
    left: 0;
    z-index: 2;
  }
`
const ArrangeMeeting = ({ locale = 'hr' }) => {
  return (
    <ArrangeWrapper>
      <Wrapper>
        <Title color="white">
          <FormattedMessage id="layoutArrangeMeetingTitle" />
        </Title>
        <ExtendedParagraph medium color="white">
          <FormattedMessage id="layoutArrangeMeetingText" />
        </ExtendedParagraph>
        <LocalisedLink
          style={{ textDecoration: 'none' }}
          to="/dogovori-sastanak"
          title={
            locale === 'hr'
              ? 'Dogovori sastanak| Litto Booking'
              : 'Arrange meeting | Litto Booking'
          }
        >
          <CustomButton bigger yellow black bold mobileCenter height="60px">
            <FormattedMessage id="becomeAPartner" />
          </CustomButton>
        </LocalisedLink>
        <Image src={couch} alt="girls on couch" />
      </Wrapper>
    </ArrangeWrapper>
  )
}
export default ArrangeMeeting
