import React, { Component } from 'react'
import styled from 'styled-components'

const FormGroup = styled.div`
  width:100%;
  margin-bottom:30px;
  position:relative;
  &::after{
    content:"";
    position:absolute;
    left:0;
    bottom:0;
    background-color:#D0021B;
    height:2px;
    width: ${props => (props.error ? '100%' : '0%')};
    transition: ${props => (props.error ? 'all 0.2s ease' : 'all 0.2s ease')};
  }
`
const Label = styled.label`
  position:absolute;
  left:30px;
  top:50%;
  margin-top:-5px;
  font-size:14px;
  color: '#797979';
  pointer-events: none;
  text-transform:uppercase;
  transition:all ease 0.2s;
`
const Input = styled.input`
  width:100%;
  padding:34px 30px 10px 30px;
  color:#1631A4;
  font-size:20px;
  background-color:#F1F1F1;
  box-shadow:none;
  border:none;
  &:focus + label,
  input.not-empty + label,
  &:valid + label{
    top:30%;
    transition:all ease 0.2s;
  }
`

const Error = styled.p`
  color:#D0021B;
  margin:0;
  left:30px;
  position:absolute;
  bottom:-20px;
  font-size:12px;
  `
class TextInputGroup extends React.Component {

  render() {
    const { type, placeholder, name, label, onChange, value, onBlur, error } = this.props
    
    return (

      <FormGroup error={error}>
        <Input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          className={value === "" ?  "is-empty" : "not-empty"}
          onChange={onChange}
          onBlur={onBlur}
          required
          pattern={name==='phone' ? "/\\(?\\d{3}\\)?[-\\/\\.\\s]?\\d{3}[-\\/\\.\\s]?/" : undefined}
        />
        <Label
          error={error}
          htmlFor={name}>{label}
        </Label>
        {error && <Error>Provjerite ovo polje</Error>}
      </FormGroup>
    )
  }
}



export default TextInputGroup;

