import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import favicon from '../images/favicon/favicon.png'
import OGImage from '../../static/img/512x512.png'

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  imageURL,
  description,
  isBlogPage,
  blogListData,
  isContactPage,
}) => {
  // const schemaOrgJSONLD = [
  //   {
  //     '@context': 'http://schema.org',
  //     '@type': 'WebSite',
  //     url,
  //     name: title,
  //     alternateName: 'Novosti',
  //   },
  // ]
  if (isContactPage) {
    return [
      {
        '@context': 'http://www.schema.org',
        '@type': 'TravelAgency',
        name: 'Litto Booking',
        url: 'https://www.litto.agency',
        logo: { '@type': 'ImageObject', url: 'https://www.litto.agency/icons/icon-48x48.png' },
        description,
        priceRange: "$$",
        image: {
          '@type': 'ImageObject',
          url: 'https://www.litto.agency/icons/icon-48x48.png',
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Lovački Put 7',
          addressLocality: 'Split',
          postalCode: '21000',
          addressCountry: 'Croatia',
        },
        hasMap:
          'https://www.google.com/maps/place/Litto+HQ+-+Travel+and+Property+management+Agency/@43.5129198,16.484668,19z/data=!3m1!4b1!4m5!3m4!1s0x13355f578b987dbf:0xeee5a890e96f088a!8m2!3d43.5129188!4d16.4852152',
        openingHours:
          'Mo 09:00-17:00 Tu 09:00-17:00 We 09:00-17:00 Th 09:00-17:00 Fr 09:00-17:00',
        telephone: '+385 91 177 0310',
      },
    ]
  }
  if (isBlogPost) {
    return [
      // ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org/',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: imageURL,
        },
        description,
        author: {
          '@type': 'Person',
          name: 'Litto Booking',
        },
        datePublished: '2019-03-18T10:30:00+01:00',
        dateModified: '2020-01-11T10:30:00+01:00',
        publisher: {
          '@type': 'Organization',
          url: 'https://litto.agency',
          name: 'Litto Booking',
          logo: {
            '@type': 'ImageObject',
            url: `https://www.litto.agency/icons/icon-48x48.png`,
          },
        },
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': url,
        },
      },
    ]
  }

  if (isBlogPage) {
    return {
      '@context': 'http://schema.org/',
      '@type': 'CollectionPage',
      name: 'Novosti iz turizma',
      headline: 'Novosti iz turizma',

      about: {
        '@type': 'Person',
        '@id': 'https://litto.agency',
        name: 'Litto Booking',
        description: 'Litto Booking Agency',
      },
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: blogListData.allMarkdownRemark.edges.map(edge => ({
          '@type': 'BlogPosting',
          name: edge.node.frontmatter.title,
          headline: edge.node.frontmatter.title,
          description: edge.node.frontmatter.seoMetaDesc,
          image: {
            '@type': 'ImageObject',
            url: `https://litto.agency`,
          },
          datePublished: edge.node.frontmatter.date,
          dateModified: edge.node.frontmatter.date,
          publisher: {
            '@type': 'Organization',
            url: 'https://development--litto-agency.netlify.com',
            name: 'Litto Booking',
            logo: {
              '@type': 'ImageObject',
              url: `https://www.litto.agency/icons/icon-48x48.png`,
            },
          },
          url: `https://litto.agency/novosti-iz-turizma-i-vodici-za-iznajmljivace${edge.node.frontmatter.slug.toLowerCase()}`,
          author: {
            '@id': 'https://litto.agency',
          },
          mainEntityOfPage: `https://litto.agency/novosti-iz-turizma-i-vodici-za-iznajmljivace${edge.node.frontmatter.slug.toLowerCase()}`,
        })),
      },
    }
  }
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: 'https://litto.agency',
    headline: 'Litto Booking',
    inLanguage: 'hr',
    mainEntityOfPage: 'https://litto.agency',
    description,
    name: 'Litto Booking',
    author: {
      '@type': 'Person',
      name: 'Litto Booking',
    },
    copyrightHolder: {
      '@type': 'Person',
      name: 'Litto Booking',
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: 'Litto Booking',
    },
    publisher: {
      '@type': 'Person',
      name: 'Litto Booking',
    },
    datePublished: '2019-03-18T10:30:00+01:00',
    dateModified: '2020-01-11T10:30:00+01:00',
    image: {
      '@type': 'ImageObject',
      url: `https://www.litto.agency/icons/icon-48x48.png`,
    },
  }
}

function SEO({
  description,
  lang,
  meta,
  title,
  imageUrl,
  postURL,
  isBlogPost,
  isBlogPage,
  blogListData,
  isContactPage,
}) {
  //schema.org tags
  const keywords =
    lang === 'hr'
      ? [
          'litto',
          'booking',
          'split',
          'hrvatska',
          'apartmani',
          'vođenje',
          'održavanje',
          'čišćenje',
          'oglašavanje',
          'ljeto',
          'sezona',
        ]
      : [
          'litto',
          'booking',
          'split',
          'croatia',
          'accommodation',
          'management',
          'maintenance',
          'cleaning',
          'summer',
          'tourist season',
        ]
  const url = `https://www.litto.agency/novosti-iz-turizma-i-vodici-za-iznajmljivace${postURL &&
    postURL.toLowerCase()}/`
  const imageURL = `https://litto.agency${imageUrl}`
  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    imageURL,
    description,
    isBlogPage,
    blogListData,
    isContactPage
  })
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const ogImageUrl = data.site.siteMetadata.siteUrl + (imageUrl || '')
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
            ]}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: postURL ? 'article' : 'website',
              },
              {
                property: `og:image`,
                content: imageUrl === undefined ? OGImage : ogImageUrl,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:image`,
                content: ogImageUrl || OGImage,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'google-site-verification',
                content: 'O0WCMI9CYd79uImgfe6HTzSnffsX7ocFiudqrUM_Y7k',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script src={withPrefix('driftChat.js')} type="text/javascript" />
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
            
            {isBlogPost && (
              <link
                rel="canonical"
                key={url}
                href={url}
                data-react-helmet="true"
              />
            )}

            
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `hr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
