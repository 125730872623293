import React, { Component } from 'react'
import cookie from '../images/homepage/cookie.jpg'
import styled from 'styled-components'
import Paragraph from './paragraph'
import { FormattedMessage } from 'react-intl'
import CookieConsent from 'react-cookie-consent'
import { yellow } from '../utlis/colors'



const Image = styled.img`
 width: 150px;
 height: 85px;
 position: relative;
 left: -20px;
`
const CookieParagraph = styled(Paragraph)`
   font-size: 16px;
   line-height: 22px;
   padding-bottom: 20px;
`
export default class CookieMessage extends Component {

  render() {
    return (
      <>
        {


          <CookieConsent
            disableStyles={false}
            style={{ width: '280px', display: 'flex', alignItems: 'center', backgroundColor: '#fff', padding: '40px', left: 'unset', right: '50px' }}
            containerClasses="cookieContainerClass"
            buttonStyle={{ backgroundColor: `${yellow}`, height: '40px', width: '200px', borderRadius: '4px', fontSize: '16px', padding: '0', margin: '0', fontWeight: 'bold' }}
            buttonText={this.props.locale === 'hr' ? "Prihvaćam" : "I accept"}
            contentStyle={{ flex: 1, margin: '0', fontSize: '16px', lineHeight: '22px' }}
            contentClasses="cookieContentClass"
          >
            <Image src={cookie} alt="cookie-image" />
            <CookieParagraph><FormattedMessage id="cookieText" /></CookieParagraph>

          </CookieConsent>

        }
      </>
    )
  }
}
