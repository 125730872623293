import { black, white, darkBlue, blue, yellow, gray } from './colors'

const pickColor = (props) => {
  switch (props) {
    case "white":
      return white
    case "darkBlue":
      return darkBlue
    case "blue":
      return blue
    case "yellow":
      return yellow
    case "gray":
      return gray
    default:
      return black
  }
}

export default pickColor