import React from 'react';
import styled from 'styled-components'
import ivanaBilic from '../images/homepage/ivana_bilic.png'
import petraSplit from '../images/homepage/petra_split.png'
import { Wrapper } from './baseStyles'
import Paragraph from './paragraph'
import marijoSplit from '../images/homepage/marijo_split.png'
import '../../node_modules/slick-carousel/slick/slick.css'
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'

const ExtendedWrapper = styled(Wrapper)`
  padding: 110px 0 0 0; 
  width: calc((100%/12)*10)!important;
 
`

const TestimonialWrapper = styled.div`
  display:flex;
  width: calc((100% / 12) * 8);
  margin: 0 auto;
  justify-content:center;
  align-items:center;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width: 768px) {
    width:100%;
    flex-direction: column;
    margin:0 auto;
    padding:5px;
    text-align:center;
  }
`
const User = styled(Paragraph)`
  margin-top: 10px;
  font-size:21px;
  line-height:29px;
  @media screen and (max-width: 768px) {
    margin-left:0;
  }
`

const Div = styled.div`
  margin-left:30px;
  @media screen and (max-width: 768px){
    margin-top:30px;
    margin-left: 0px
  }  
  `

const testimonialsHr = [
	{
		image: ivanaBilic,
		paragraph: "Sve pohvale mladoj ekipi. Odlična suradnja, uvijek su dostupni, svidja mi se njihov ured, uvijek su nasmijani i sve je prošlo u najboljem redu. Nadam se i budućoj suradnji!",
		name: "Vanja, Podstrana"
	},
	{
		image: petraSplit,
		paragraph: "Super agencija, velika preporuka za svakog iznajmljivača!",
		name: "Mirjana, Trogir"
	},
	{
		image: marijoSplit,
		paragraph: "Sjajan primjer izvrsne turističke agencije! Preporuke!",
		name: "Sparut Boats, Split"
	},
	{
		image: marijoSplit,
		paragraph: "Fantastična agencija! Osoblje je ljubazno i vrlo profesionalno. Brzo su odgovorili na sva moja pitanja i trudili se učiniti moj boravak što ugodnijim!",
		name: "Frančesko Magyar, Split"
	},
	{
		image: marijoSplit,
		paragraph: "Partnerstvo sa Litto agencijom mi je zanimljivo jer se radi o mladoj ekipi koja ima veliko iskustvo u turizmu i internet poslovanju.",
		name: "Ivana, Split"
	},
]

const testimonialsEn = [
	{
		image: ivanaBilic,
		paragraph: "Kudos to the young team. Great collaboration, they are always available, I like their office, they are always smiling and everything went extremely well. Excited for the  future cooperation!",
		name: "Vanja, Podstrana"
	},
	{
		image: petraSplit,
		paragraph: "Great agency, huge recommendation for all renters!",
		name: "Mirjana, Trogir"
	},
	{
		image: marijoSplit,
		paragraph: "Great example of an excellent travel agency! Highly recommended!",
		name: "Sparus Boats, Split"
	},
	{
		image: marijoSplit,
		paragraph: "Fantastic agency! The staff is friendly and highly professional. They were quick to respond to any of my questions and worked hard to make my stay the best one I've ever had!",
		name: "Frančesko Magyar, Split"
	},
	{
		image: marijoSplit,
		paragraph: "Thank you for everything! I look forward to further cooperation. A warm recommendation for all renters and guests. 👏🏻",
		name: "Ivana, Split"
	},
]

class Testimonial extends React.Component {

	render() {
		let testimonials = testimonialsHr
		if (this.props.locale === 'en') testimonials = testimonialsEn
		const settings = {
			dots: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1

		}
		return (
			<ExtendedWrapper>
				<Slider {...settings}>
					{testimonials.map(testimonial => <TestimonialWrapper key={testimonial.name}>
						<TestimonialWrapper>
							<Div>
								<Paragraph medium>
									{testimonial.paragraph}
								</Paragraph>
								<User>&#x2015; {testimonial.name}</User>
							</Div>
						</TestimonialWrapper>
					</TestimonialWrapper>
					)}
				</Slider>
			</ExtendedWrapper>

		)
	}

}

export default Testimonial