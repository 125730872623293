import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './baseStyles'
import { yellow, white } from '../utlis/colors'
import { FormattedMessage, injectIntl } from 'react-intl'
import LocalisedLink from '../components/localisedLink'

const List = styled.ul`
  list-style: none;
  @media screen and (max-width: 1080px) {
    display: flex;
    flex-wrap: wrap;
  }
`
const ListElement = styled.li`
  display: inline;
  @media screen and (max-width: 1080px) {
    width: calc(33% + 20px);
    padding-bottom: 10px;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`
const VerticalLine = styled.span`
  display: inline-block;
  margin: 0 5px;
  color: white;
  @media screen and (max-width: 1080px) {
    display: none;
  }
`
const FooterWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    position: relative;
    padding-bottom: 40px;
  }
`

const StyledLink = styled(LocalisedLink)`
  font-size: 15px;
  line-height: 15px;
  font-family: 'Larsseit-Medium';
  color: ${white};
  text-decoration: none;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    color: ${yellow};
    transition: all ease 0.2s;
  }
`

const MadeByLocastic = styled.a`
  color: ${white};
  margin: 0px;
  text-decoration: none;
  transition: all ease 0.2s;
  @media screen and (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }
  &:hover {
    color: ${yellow};
    transition: all ease 0.2s;
  }
`

function Footer({ locale }) {
  return (
    <div style={{ backgroundColor: 'black', width: '100%', padding: '25px 0' }}>
      <FooterWrapper>
        <List>
          <ListElement>
            <StyledLink
              to="/vodjenje-apartmana-split"
              title={
                locale === 'hr'
                  ? 'Vođenje, održavanje i čišćenje apartmana Split | Litto Booking'
                  : 'Property management, advertising and maintenance | Litto Booking'
              }
            >
              <FormattedMessage id="servicesPage" />
            </StyledLink>
            <VerticalLine>&#x2015;</VerticalLine>
          </ListElement>
          <ListElement>
            <StyledLink
              to="/o-nama"
              title={
                locale === 'hr'
                  ? 'O nama | Litto Booking'
                  : 'About us | Litto Booking'
              }
            >
              <FormattedMessage id="aboutPage" />
            </StyledLink>
            <VerticalLine>&#x2015;</VerticalLine>
          </ListElement>

          <ListElement>
            <StyledLink
              to="/cesta-pitanja"
              title={
                locale === 'hr'
                  ? 'Često postavljena pitanja | Litto Booking'
                  : 'FAQ | Litto Booking'
              }
            >
              <FormattedMessage id="faqPage" />
            </StyledLink>
            <VerticalLine>&#x2015;</VerticalLine>
          </ListElement>
          {locale !== 'en' && (
            <ListElement>
              <StyledLink
                to="/novosti-iz-turizma-i-vodici-za-iznajmljivace"
                title="Novosti iz turizma i vodiči za iznajmljivače | Litto Booking"
              >
                <FormattedMessage id="blogPage" />
              </StyledLink>
              <VerticalLine>&#x2015;</VerticalLine>
            </ListElement>
          )}

          <ListElement>
            <StyledLink
              to="/zastita-podataka"
              title={
                locale === 'hr'
                  ? 'Zaštita podataka | Litto Booking'
                  : 'Privacy policy | Litto Booking'
              }
            >
              <FormattedMessage id="footerPrivacy" />
            </StyledLink>

            <VerticalLine>&#x2015;</VerticalLine>
          </ListElement>
          <ListElement>
            <StyledLink
              to="/kontakt"
              title={
                locale === 'hr'
                  ? 'Kontatk | Litto Booking'
                  : 'Contact | Litto Booking'
              }
            >
              <FormattedMessage id="contactPage" />
            </StyledLink>
          </ListElement>
        </List>
        <MadeByLocastic
          target="_blank"
          href="https://locastic.com"
          rel="external"
          title="Web and mobile development, design and UX agency"
        >
          #madebylocastic
        </MadeByLocastic>
      </FooterWrapper>
    </div>
  )
}

export default injectIntl(Footer)
