import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './baseStyles'
import OffersList from './offersList';
import H2 from './h2';
import { blue } from '../utlis/colors';
import { FormattedMessage } from 'react-intl';
import Paragraph from './paragraph';
import pic from '../images/ugovor.svg'

const OffersWrapper = styled.div`
  background-color: #F9F9F9;
  padding:70px 0 0 0;
  position:relative;
  &:after{
    content:"";
    position:absolute;
    height: calc(100% - 610px);
    width: 100%;
    background-color:${blue};
    bottom:0;
  }
  @media screen and (max-width:768px){
    padding: 35px 0 0 0;
    }
`
const Title = styled(H2)`
  padding-bottom: 20px; 

`

const FlexWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }

`
const ImageWrapper = styled.div`
  overflow: hidden;
  width: 55%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  @media screen and (max-width: 768px){
    width: 100%
  }
`

const Image = styled.img`
  width: 100%;
  position: absolute;
`

const TextWrapper = styled.div`
  width: calc(45% - 15px);
  z-index: 2;
  padding-right: 15px;
  padding-top: 70px;

  @media screen and (max-width: 768px){
    padding-top: 0px;
    width: 100%;
    padding-bottom: 50px;
    text-align: center;
  
  }
`

const Offers = () => {
  return (
    <OffersWrapper>
      <Wrapper>
        <FlexWrapper>
          <TextWrapper>
            <Title color="blue"><FormattedMessage id="homepageOffersListTitle"/></Title>
            <Paragraph>
              <FormattedMessage id="homepageOffersListText"
                values={{
                  b: (...chunks) => <strong>{chunks}</strong>
                }}
              />
            </Paragraph>
          </TextWrapper>
          <ImageWrapper>
            <Image alt="offer image" src={pic}></Image>
          </ImageWrapper>
        </FlexWrapper>
        <OffersList />
      </Wrapper>
    </OffersWrapper>
  )
}

export default Offers;