import React from 'react'
import { Link } from 'gatsby'
import { injectIntl} from 'react-intl'
import styled from 'styled-components'

import locales from '../constants/locales'
import { blue } from '../utlis/colors';

const StyledLink = styled(Link)`
  font-size: 17px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  position:relative;
  transition: all ease 0.2s;
  font-family: 'Larsseit-Medium' ;
  &:hover{
    color: yellow;
    transition: all ease 0.2s;
    @media screen and (max-width: 960px){
      color:${blue};
      font-weight:800;
    }
  }
  @media screen and (max-width: 960px){
    color:black;
    font-size:23px
  }
`

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`
  return <StyledLink {...props} to={path} />
}

export default injectIntl(LocalizedLink)
