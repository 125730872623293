import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './baseStyles'
import CustomButton from './button'
import send from '../images/ic_send.svg'
import addToMailChimp from 'gatsby-plugin-mailchimp'
import MDSpinner from 'react-md-spinner'
import { blue } from '../utlis/colors'
import { FormattedMessage } from 'react-intl'
import Paragraph from '../components/paragraph'

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const NewsletterWrapper = styled(Wrapper)`
  text-align: center;
  padding: 50px 0;
  margin: 15px auto 30px auto;
`
const Inline = styled.div`
  display: inline-block;
  text-align: left;
`
const Input = styled.input`
  width: 420px;
  height: 50px;
  padding: 0 15px;
  margin-right: 15px;
  font-size: 21px;
  background: #eee;
  border: none;
  ::placeholder {
    color: #020512;
    font-size: 21px;
    line-height: 29px;
    font-family: 'Grotesk-Medium';
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    line-height: 50px;
  }
  @media screen and (max-width: 560px) {
    width: 290px;
  }
  @media screen and (max-width: 390px) {
    width: 220px;
  }
`
const Title = styled.p`
  font-size: 21px;
  line-height: 29px;
  color: ${props =>
    !props.error ? (!props.mailExistsError ? blue : 'red') : 'red'};
  font-family: 'Larsseit-Medium';
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: center;
`
const DesktopText = styled.span`
  display: block;
  @media screen and (max-width: 560px) {
    display: none;
  }
`
const MobileImage = styled.img`
  display: none;
  @media screen and (max-width: 560px) {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
`

const NewsletterParagraph = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: calc((100% / 12) * 8);
  margin: 0 auto;
  margin-bottom: 30px;
`
export default class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false,
      error: false,
      mailExistsError: false,
      success: false,
    }
  }

  handleSubmit = async () => {
    const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    this.setState({ mailExistsError: false })
    this.setState({ success: false })
    if (
      this.state.email !== '' &&
      reg.test(String(this.state.email).toLowerCase()) === true
    ) {
      this.setState({ loading: true })
      const res = await addToMailChimp(this.state.email, {
        LANG: this.props.locale,
      })

      await this.setState({ loading: false })
      this.setState({ email: '' })
      this.setState({ error: false })
      if (res.result === 'success') this.setState({ success: true })
      if (res.result === 'error') {
        this.setState({ mailExistsError: true })
      }
    } else this.setState({ error: true })
  }
  render() {
    return (
      <NewsletterWrapper>
        <Inline>
          {this.state.success ? (
            <Title>
              <FormattedMessage id="newsletterSuccess" />
            </Title>
          ) : this.state.error ? (
            <Title error={this.state.error}>
              {this.state.error && <FormattedMessage id="newsletterError" />}
            </Title>
          ) : this.state.mailExistsError ? (
            <Title mailExistsError={this.state.mailExistsError}>
              <FormattedMessage id="newsletterMailExistsError" />
            </Title>
          ) : (
            <Title>
              <FormattedMessage id="newsletterTitle" />
            </Title>
          )}
          <NewsletterParagraph>
            <Paragraph>
              <FormattedMessage
                id={
                  this.state.success
                    ? 'newsLetterParagraphSuccess'
                    : 'newsletterParagraph'
                }
              />
            </Paragraph>
          </NewsletterParagraph>

          {!this.state.success && (
            <FlexCenter>
              <label htmlFor="newsletter" hidden>
                newsletter
              </label>
              <Input
                name="newsletter"
                type="text"
                placeholder={
                  this.props.locale === 'hr'
                    ? 'Unesi svoj email'
                    : 'Enter your email'
                }
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
              {!this.state.loading ? (
                <CustomButton
                  height="60px"
                  label={'Prijavi se'}
                  fontSize="20px"
                  onClick={this.handleSubmit}
                >
                  <DesktopText>
                    <FormattedMessage id="newsletterButtonText" />
                  </DesktopText>
                  <MobileImage src={send} alt="send" />
                </CustomButton>
              ) : (
                <MDSpinner
                  color1="#194092"
                  color2="#f2eb30"
                  color3="#194092"
                  color4="#194092"
                  duration={2000}
                  size={50}
                />
              )}
            </FlexCenter>
          )}
        </Inline>
      </NewsletterWrapper>
    )
  }
}
